import axios from 'axios';
import Mediator from '../../common/scripts/mediator';
import Utils from '../../common/scripts/utils';

class MiniFilter {
    constructor() {
        this.mediator = new Mediator();

        this.form = 'j-mini-filter';
        this.submit = 'j-mini-filter-submit';
        this.disableClass = 'disabled';
    }

    init() {
        this._initElements();
        this._bindEvents();
    }

    _initElements() {
        this.$form = document.querySelector(`.${this.form}`);
        this.$submit = document.querySelector(`.${this.submit}`);
    }

    _bindEvents() {
        this.$form.addEventListener('change', () => {
            this.sendData();
        });
    }

    sendData() {
        const sendData = new FormData(this.$form);
        const action = this.$form.dataset.actionChange;

        this._disableSubmit();

        axios.post(action, sendData)
            .then((response) => {
                const {data} = response.data;
                const {borderSliders} = data;

                this._replaceCountInButton(data.searchButtonText);

                if (borderSliders) {
                    this.mediator.publish('parametricfilterSend', borderSliders);
                }
            })
            .catch((error) => { // eslint-disable-line dot-notation
                console.error(error);
            })
            .then(() => {
                this._enableSubmit();
            });
    }

    _replaceCountInButton(test) {
        Utils.clearHtml(this.$submit);
        Utils.insetContent(this.$submit, `${test}`);
    }

    _disableSubmit() {
        this.$submit.classList.add(this.disableClass);
    }

    _enableSubmit() {
        this.$submit.classList.remove(this.disableClass);
    }
}

export default MiniFilter;

