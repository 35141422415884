/**
 * DEPENDENCIES
 */
import carouselTemplate from './templates/carousel.twig';
import contentTemplate from './templates/content.twig';
import imageTemplate from './templates/image.twig';
import Mediator from 'common/scripts/mediator';
import template from './popup.twig';
import Utils from 'common/scripts/utils';
import videoTemplate from './templates/video.twig';

const mediator = new Mediator();

class Popup {
    init(options) {
        this.body = document.body;
        this.template = options.template === 'carouselTemplate' ? carouselTemplate : template;
        this.templateContent = options.templateContent || false;
        this.targets = Array.from(document.querySelectorAll(options.target));
        this.closeButtonAriaLabel = options.closeButtonAriaLabel || 'Закрыть всплывающее окно';
        this.stateClass = 'b-popup_state_open';
        this.noShowByClick = options.noShowByClick;
        this.slider = options.slider;
        this.bindEvents();
    }

    /**
     * Вешаем слушателей событий
     */
    bindEvents() {
        if (this.targets.length) {
            this.targets.forEach((target) => {
                if (this.noShowByClick) {
                    return;
                }

                target.addEventListener('click', (event) => {
                    event.preventDefault();

                    this.makeOpen(target);
                });
            });
        }

        document.addEventListener('keyup', (element) => {
            this.closeOnPressButton(element);
        });
    }

    /**
     * Байнд событий после создания popup.
     */
    bindEventsAfterOpen() {
        this.closeButton.addEventListener('click', this.close.bind(this));
        this.popup.addEventListener('click', (element) => {
            this.closeOnClickOverlay(element);
        });
    }

    /**
     * Добавляет body position:fixed, дабы избежать прокрутки страницы при открытом попапе.
     * Когда-нибудь перенесем в Mediator.
     */
    fixedBody() {
        this.body.classList.toggle('body-fixed');
    }

    makeOpen(target) {
        this.contentTypeCheck(target);
    }

    /* eslint-disable max-statements */

    /**
     * Проверка типа попапа - статика/ajax.
     * @param {Node} target - Одна из целей
     */
    contentTypeCheck(target) {
        const dataAttributeHref = target.dataset.href;
        const dataAttributeAjax = target.dataset.ajax;
        const dataAttributeAjaxData = $.param(target.dataset);
        const dataAttributeImages = target.dataset.image;
        const dataAttributeVideo = target.dataset.video;
        const dataAttributeContent = target.dataset.popupContent;

        if (dataAttributeHref) {
            const contentId = document.getElementById(dataAttributeHref);

            if (!contentId) {
                console.error(`Создай контентный блок попапа с id "${dataAttributeHref}"`);

                return;
            }

            const staticContent = contentId.innerHTML;

            this.href = dataAttributeHref;
            this.outputOnDisplay(staticContent, this.template);
            this.insertDataFromAttribute(target);
            this.open();
        } else if (dataAttributeAjax) {
            this.outputOnDisplay('', this.template);
            this.send(dataAttributeAjaxData, this.open.bind(this));
        } else if (dataAttributeImages) {
            this.outputOnDisplay(dataAttributeImages, imageTemplate);
            this.open();
        } else if (dataAttributeVideo) {
            this.outputOnDisplay(dataAttributeVideo, videoTemplate);
            this.open();
        } else if (dataAttributeContent) {
            const innerHtml = target.innerHTML;

            this.outputOnDisplay(innerHtml, contentTemplate);
            this.open();
        }
    }

    /* eslint-enable max-statements */

    /**
     * Вывод попапа на экран.
     * @param {String} content - контент из статики или из json.
     * @param {Template} defTemplate - Шаблон для вывода
     */
    outputOnDisplay(content, defTemplate) {
        this.setData(content);
        this.insertTemplate(defTemplate);
        this.getElements();
    }

    /**
     * Подготовка данных для шаблона
     * @param {String} content - контент из статики или из json.
     */
    setData(content) {
        this.data = {
            content,
            slider: this.slider
        };
    }

    insertTemplate(defTemplate = this.template) {
        this.body.insertAdjacentHTML('afterbegin', defTemplate(this.data));
    }

    getElements() {
        this.popup = document.querySelector('.b-popup');
        this.content = document.querySelector('.b-popup__content');
        this.wrap = document.querySelector('.b-popup__wrap');
        this.closeButton = this.popup.querySelector('.b-popup__close');
        this.overlay = this.popup.querySelector('.b-popup__overlay');

        this.bindEventsAfterOpen();
    }

    /**
     * Вставка контента
     * @param {String} popup - элемент, куда вставляется контент. (не сам попап, а его контентная часть!)
     * @param {String} content - вставляемый контент.
     */
    static insetContent(popup, content) {
        Utils.clearHtml(popup);
        Utils.insetContent(popup, content);
    }

    /**
     * Метод отправки ajax запроса
     * @param {Object} sentData - отправляемые данные.
     * @param {Function} complete - функция открытия попапа
     * @return {Boolean} - false - если нет интернета, запрос прерывается.
     * @return {Boolean} - true - успешное выполнение запроса.
     */
    send(sentData, complete = function() {}) {
        if (!this.checkInternet()) {
            this.resending(sentData);

            return false;
        }

        const that = this;
        const url = this.target.dataset.ajax;
        const callback = {
            success(req) {
                if (req.request.result) {
                    if (that.templateContent) {
                        Popup.insetContent(that.content, that.templateContent(req.data));
                    } else {
                        Popup.insetContent(that.content, req.data.content);
                    }

                    complete();
                } else {
                    that.errorHandler('data');
                    console.error(req.request.errors);
                }
            },

            error(err) {
                that.errorHandler('server');
                console.error(`server error ${err}`);
            }
        };

        Utils.send(sentData, url, callback);

        return true;
    }

    closeOnClickOverlay(element) {
        if (element.target === this.overlay || element.target === this.wrap) {
            this.close();
        }
    }

    closeOnPressButton(element) {
        const keyCodeESC = 27;

        if (element.keyCode === keyCodeESC) {
            this.close();
        }
    }

    /**
     * Метод открывает попап
     */
    open() {
        this.popup.classList.add(this.stateClass);
        this.fixedBody();

        mediator.publish('openPopup', this);
    }

    /**
     * Метод закрывает попап
     */
    close() {
        this.popup.classList.remove(this.stateClass);
        this.remove();
        this.fixedBody();

        mediator.publish('closePopup', this);
    }

    /**
     * Удаление попапа со страницы.
     */
    remove() {
        Utils.removeElement(this.popup);
    }

    /**
     * Метод обработки ошибок.
     * @param {String} typeError - тип ошибки.
     */
    errorHandler(typeError) {
        const errorList = [{
            type: 'internet',
            html: '<h1>Вы не подключены к интернету. Повторите запрос позднее</h1>'
        }, {
            type: 'server',
            html: '<h1>На сервере произошла ошибка. Повторите запрос позднее</h1>'
        }, {
            type: 'data',
            html: '<h1>На сервере произошла ошибка. Повторите запрос позднее</h1>'
        }];

        errorList.forEach((item) => {
            if (typeError === item.type) {
                Popup.insetContent(this.content, item.html);
            }
        });
    }

    /**
     * Проверка на наличие интернета.
     * @return {boolean} true - интернет есть.
     * @return {boolean} false - интернетa нет.
     */
    checkInternet() {
        clearInterval(this.setInterval);
        if (!Utils.checkInternetConnection()) {
            this.errorHandler('internet');

            return false;
        }

        return true;
    }

    /**
     * Повторный запрос на сервер.
     * @param {object} sentData - отрправляемые данные.
     *
     */
    resending(sentData) {
        const interval = 5000;
        const that = this;

        this.setInterval = setInterval(() => {
            that.send(sentData);
        }, interval);
    }

    /**
     * Создает скрытые инпуты с данными из атрибутов
     * @param {object} target - Актив элемент
     */
    insertDataFromAttribute(target) {
        const attributes = target.dataset;
        const form = this.popup.querySelector('form');

        if (!form) {
            return;
        }

        for (const key in attributes) {
            if (key === 'href' || key === 'action') {
                continue; // eslint-disable-line
            }

            const input = document.createElement('input');

            input.type = 'hidden';
            input.name = key;
            input.value = attributes[key];

            form.appendChild(input);
        }
    }
}

export default Popup;
