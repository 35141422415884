import axios from 'axios';
import newsTmpl from './template/novelty.twig';
import Utils from '../../common/scripts/utils';

class News {
    constructor() {
        this.mainSelecrot = '.j-news-main';
        this.containerSelecrot = '.j-news-container';
        this.newsSelecrot = '.j-news-item';
        this.loadMoreSelecrot = '.j-news-load-more';
    }

    init() {
        this._initElements();
        this._bindEvents();
    }

    _initElements() {
        this.main = document.querySelector(this.mainSelecrot);
        this.container = document.querySelector(this.containerSelecrot);
        this.news = Array.from(document.querySelectorAll(this.newsSelecrot));
        this.loadMore = document.querySelector(this.loadMoreSelecrot);
    }

    _bindEvents() {
        this.loadMore.addEventListener('click', (event) => {
            event.preventDefault();

            this._loadMore();
        });
    }

    _loadMore() {
        const sendData = new FormData();
        const action = this.main.dataset.action;

        this.news = Array.from(document.querySelectorAll(this.newsSelecrot));
        sendData.append('showNews', this.news.length);

        this._disableLoadMore();

        axios.post(action, sendData)
            .then((response) => {
                const {data} = response.data;

                this._insertAfterContent(data);

                if (!data.showMore) {
                    this._hideLoadMore();
                }
            })
            .catch((error) => { // eslint-disable-line
                console.error(error);
            })
            .then(() => {
                this._enableLoadMore();
            });
    }

    _insertAfterContent(data) {
        Utils.insetContent(this.container, newsTmpl(data));
    }

    _disableLoadMore() {
        this.loadMore.classList.add('disabled');
    }

    _enableLoadMore() {
        this.loadMore.classList.remove('disabled');
    }

    _hideLoadMore() {
        Utils.removeElement(this.loadMore);
    }
}

export default News;

