/**
 * Отдает пдф из запроса или по выбранному селектору
 * P.s На локальном сервене не работает! (не разбирался)
 *
 * Пример / и все дата атрибуты
 *
 * <button class="j-download-pdf"
 *    data-action="/tests/pdf.json"      - если pdf отдается из бека
 *    data-file-name="flat-15"           - Имя файла
 *    data-file-quality="3"              - Качество от 1 до 5, где 1 - плохое, 5 - вери гуд
 *    data-selector=".flat-pdf"          - если нужно сделать pdf из блока (Приоритет перед data-action!)
 *    data-type="flat"                   - Остальные дата атрибуты идут в отправку запроса форматом FormData
 *    data-id="15"                       - Остальные дата атрибуты идут в отправку запроса форматом FormData
 *    >Download</button>
 */

import axios from 'axios';
import html2pdfJs from 'html2pdf.js';
import Utils from '../../common/scripts/utils';

class Html2pdf {
    constructor(options) {
        this.targets = this._initVariable(options.target);
        this.defQuality = '3';
        this.windowPdf = null;
        this.hrefPageLoad = '/pages/load.html';
    }

    init() {
        this._bindEvents();
    }

    _bindEvents() {
        this.targets.forEach((target) => {
            target.addEventListener('click', (event) => {
                event.preventDefault();
                if (!Utils.isIE() && !Utils.isAndroid()) {
                    // Открываем окно сразу что бы браузеры не ругали то всплывающие окна
                    this.windowPdf = window.open(this.hrefPageLoad, '_target');
                }

                this._clickTarget(target);
            });
        });
    }

    _clickTarget(target) {
        const settings = this._collectData(target);

        if (settings.selector) {
            this._downloadFromSelector(settings);

            return;
        }

        this._loadPdf(settings);
    }

    _collectData(targetEl) {
        const settings = {
            target  : targetEl,
            sendData: new FormData()
        };

        const dataset = {...targetEl.dataset};

        if (dataset.selector) {
            settings.selector = dataset.selector;
            delete dataset.selector;
        }

        if (dataset.action) {
            settings.action = dataset.action;
            delete dataset.action;
        }

        if (dataset.fileName) {
            settings.fileName = dataset.fileName;
            delete dataset.fileName;
        }

        if (dataset.quality) {
            const max = 5;
            const min = 1;

            settings.quality = dataset.quality > max ? max : dataset.quality < min ? min : dataset.quality; // eslint-disable-line
            delete dataset.quality;
        }

        for (const prop in dataset) {
            if (dataset.hasOwnProperty(prop)) { // eslint-disable-line
                settings.sendData.append(prop, dataset[prop]);
            }
        }

        return settings;
    }

    _loadPdf(settings) {
        if (!settings.action) {
            console.error(`Не найдет атрибут data-action у node элемента "${settings.target}"`);

            return;
        }

        axios.post(settings.action, settings.sendData)
            .then((response) => {
                const {data} = response.data;

                settings.pdfHtml = data.pdfHtml;
                this._downloadFromAjax(settings);
            })
            .catch((error) => { // eslint-disable-line
                console.error(error);
            });
    }

    _downloadFromSelector(settings) {
        const pdfElement = document.querySelector(settings.selector);

        if (!pdfElement) {
            console.error(`Node элемент с селектором "${settings.selector}" на странице не найден.`);

            return;
        }

        this._downloadPdf(settings, pdfElement);
    }

    _downloadFromAjax(settings) {
        const pdfElement = document.createElement('div');

        pdfElement.innerHTML = settings.pdfHtml;
        this._downloadPdf(settings, pdfElement);
    }

    _downloadPdf(settings, element) {
        const opt = {
            filename   : settings.fileName || 'flat-pdf',
            html2canvas: {scale: settings.quality || this.defQuality}
        };

        html2pdfJs()
            .set(opt)
            .from(element)
            .toPdf()
            .get('pdf')
            .then((pdf) => {
                if (Utils.isIE() || !this.windowPdf || Utils.isAndroid()) {
                    pdf.save();

                    return;
                }

                this.windowPdf.onload = () => {
                    this.windowPdf.location.href = pdf.output('bloburl');
                };

                this.windowPdf.location.href = pdf.output('bloburl');
            });
    }

    /**
     * Инитит переменную что бы на выходе был массив из ноды
     * @param  {object} element - селектор или нод элемент(ы)
     * @return {array} variable - Массив из нод элементов
     */
    _initVariable(element) {
        let variable = [];

        if (typeof element === 'string') {
            variable = Array.from(document.querySelectorAll(element));
        } else if (typeof element === 'object') {
            if (element.length) {
                variable = Array.from(element);
            } else {
                variable = [element];
            }
        }

        return variable;
    }
}

export default Html2pdf;

