import axios from 'axios';
import Mediator from '../../common/scripts/mediator';
import Utils from '../../common/scripts/utils';

class ParametricFilter {
    constructor() {
        this.mediator = new Mediator();

        this.formClass = 'j-parametric-filter';
        this.submitClass = 'j-parametric-submit';
        this.submitTextClass = 'j-parametric-submit-text';
        this.resetClass = 'j-parametric-reset-filter';
        this.inputFilterClass = 'b-circle-checkbox';
        this.inputFilterDisableClass = 'b-circle-checkbox_is_disable';
        this.searchTextClass = 'j-parametric-search-text';

        // options
        this.optionsMoreClass = 'j-parametric-more-options';
        this.additionOptionClass = 'j-parametric-additional-option';
        this.additionShowClass = 'param-filter__addition_is_active';
        this.optionsMoreActive = 'param-filter__show-more_is_active';

        this.formData = new FormData();
        // Дополнительно отправляемые данные (например сортировка)
        this.additionData = new FormData();
    }

    init() {
        this._initElements();
        this._bindEvents();
        this._initFormData();
    }

    _initElements() {
        this.form = document.querySelector(`.${this.formClass}`);
        this.submit = document.querySelector(`.${this.submitClass}`);
        this.submitText = document.querySelector(`.${this.submitTextClass}`);
        this.reset = document.querySelector(`.${this.resetClass}`);
        this.optionsMore = document.querySelector(`.${this.optionsMoreClass}`);
        this.additionOption = document.querySelector(`.${this.additionOptionClass}`);
        this.inputsFilter = Array.from(document.querySelectorAll(`.${this.inputFilterClass}`));
        this.searchText = document.querySelector(`.${this.searchTextClass}`);
    }

    _bindEvents() {
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();

            const data = new FormData(this.form);

            this._sendFilter(data);
        });

        this.form.addEventListener('change', () => {
            this.getCountFoundFlats();
        });

        this.optionsMore.addEventListener('click', (event) => {
            event.preventDefault();

            this._switchAdditionOptions();
        });

        this.reset.addEventListener('click', (event) => {
            event.preventDefault();

            this._resetForm();
        });

        // Принимаю данные доп. данные (FormData)
        this.mediator.subscribe('parametric:filter-send-addition-data', (data) => {
            if (!data) {
                return;
            }

            Utils.mergeFormData(this.additionData, data);
        });

        // Передаю доп. данные в сортировку
        this.mediator.subscribe('parametric:sort-query-addition-data', () => {
            this.mediator.publish('parametric:sort-send-addition-data', this.formData);
        });
    }

    _initFormData() {
        this.formData = new FormData(this.form);
    }

    getCountFoundFlats() {
        const sendData = new FormData(this.form);
        const action = this.form.dataset.actionChange;

        this._submitDisable();

        axios.post(action, sendData)
            .then((response) => {
                const {data} = response.data;
                const {borderSliders} = data;

                this._replaceCountFlats(data.searchButtonText);
                this._disableInputs(data.visible);
                this._replaceSearchText(data.searchText);

                if (borderSliders) {
                    this.mediator.publish('parametricfilterSend', borderSliders);
                }
            })
            .catch((error) => { // eslint-disable-line dot-notation
                console.error(error);
            })
            .then(() => {
                this._submitEnable();
            });
    }

    _sendFilter(sendData) {
        this.additionData = new FormData();
        this.formData = Utils.copyFormData(sendData);

        const action = this.form.getAttribute('action');

        this._queryOnAdditionData();
        this._mergeAdditionData(sendData);

        // Включаем лоадер для контентной области
        this.mediator.publish('parametric:run-load-content');

        axios.post(action, sendData)
            .then((response) => {
                const {data} = response.data;

                // Передаем туда где все это будет выводится
                this.mediator.publish('parametric:filtered', data);
                this._setUrl(sendData);
            })
            .catch((error) => { // eslint-disable-line dot-notation
                console.error(error);
            })
            .then(() => {
                this.additionData = new FormData();

                // Выключаем лоадер контентной области
                this.mediator.publish('parametric:stop-load-content');
            });
    }

    _mergeAdditionData(formData) {
        if (!(formData instanceof FormData)) {
            return;
        }

        if (!(this.additionData instanceof FormData)) {
            return;
        }

        Array.from(this.additionData).forEach((item) => {
            const keyId = 0;
            const valId = 1;

            formData.append(`${item[keyId]}`, item[valId]);
        });
    }

    _setUrl(formData) {
        const url = new URLSearchParams();

        Array.from(formData).forEach((item) => {
            const keyId = 0;
            const valId = 1;

            url.append(`${item[keyId]}`, item[valId]);
        });

        window.history.pushState(null, null, `?${url.toString()}`);
    }

    _replaceCountFlats(text) {
        Utils.clearHtml(this.submitText);
        Utils.insetContent(this.submitText, `${text}`);
    }

    _switchAdditionOptions() {
        if (this.additionOption.classList.contains(this.additionShowClass)) {
            this.additionOption.classList.remove(this.additionShowClass);
            this.optionsMore.classList.remove(this.optionsMoreActive);
        } else {
            this.additionOption.classList.add(this.additionShowClass);
            this.optionsMore.classList.add(this.optionsMoreActive);
        }
    }

    _submitDisable() {
        this.submit.classList.add('disabled');
    }

    _submitEnable() {
        this.submit.classList.remove('disabled');
    }

    _queryOnAdditionData() {
        // Делаю запрос на получение доп. данных
        this.mediator.publish('parametric:filter-query-addition-data');
    }

    _disableInputs(visible) {
        this.inputsFilter.forEach((filter) => {
            const input = filter.querySelector('input');
            const name = input.getAttribute('name');

            if (visible.includes(name)) {
                filter.classList.remove(this.inputFilterDisableClass);
                input.removeAttribute('disabled');
            } else {
                filter.classList.add(this.inputFilterDisableClass);
                input.setAttribute('disabled', 'disabled');
                input.checked = false;
            }
        });
    }

    _resetForm() {
        this.form.reset();
        this._uncheckInputsFilter();
        this.mediator.publish('paramfilterReset');

        const data = new FormData(this.form);

        this.getCountFoundFlats();
        this._sendFilter(data);
    }

    _uncheckInputsFilter() {
        this.inputsFilter.forEach((filter) => {
            const input = filter.querySelector('input');

            input.removeAttribute('checked');
        });
    }

    _replaceSearchText(searchText) {
        if (!searchText) {
            return;
        }

        if (typeof searchText !== 'string') {
            return;
        }

        this.searchText.textContent = searchText;
    }
}

export default ParametricFilter;
