import Utils from '../../common/scripts/utils';

class Navigation {
    constructor() {
        this.hamburger = 'j-navigation-hamburger';
        this.close = 'j-navigation-close';
        this.cover = 'j-navigation-cover';
        this.nav = 'j-navigation-container';
        this.showNavClass = 'b-navigation__wrapper_is-show';
        this.showCoverClass = 'b-navigation__cover_is-show';

        this.maxWidthNav = 960;
    }

    init() {
        this._initElements();
        this._bindEvents();
    }

    _initElements() {
        this.$hamburger = document.querySelector(`.${this.hamburger}`);
        this.$close = document.querySelector(`.${this.close}`);
        this.$cover = document.querySelector(`.${this.cover}`);
        this.$nav = document.querySelector(`.${this.nav}`);
        this.$body = document.querySelector(`body`);
    }

    _bindEvents() {
        this.$hamburger.addEventListener('click', () => {
            this._openNav();
        });

        this.$close.addEventListener('click', () => {
            this._closeNav();
        });

        this.$cover.addEventListener('click', () => {
            this._closeNav();
        });

        // Если ширина больше чем нужно, показывать десктопную навигацию
        window.addEventListener('resize', () => {
            if (window.innerWidth >= this.maxWidthNav) {
                this._resetNav();
            }
        });
    }

    _openNav() {
        const delayRender = 10;

        this._addOverflowHidden();

        Utils.show(this.$cover);
        Utils.show(this.$nav, 'flex');

        setTimeout(() => {
            this.$cover.classList.add(this.showCoverClass);
            this.$nav.classList.add(this.showNavClass);
        }, delayRender);
    }

    _closeNav() {
        const duration = 300;

        this.$cover.classList.remove(this.showCoverClass);
        this.$nav.classList.remove(this.showNavClass);

        setTimeout(() => {
            Utils.hide(this.$cover);
            Utils.hide(this.$nav);

            this._removeOverflowHidden();
        }, duration);
    }

    _resetNav() {
        this.$cover.setAttribute('style', '');
        this.$nav.setAttribute('style', '');

        this.$cover.classList.remove(this.showCoverClass);
        this.$nav.classList.remove(this.showNavClass);
    }

    _addOverflowHidden() {
        this.$body.classList.add('overflow-hidden');
    }

    _removeOverflowHidden() {
        this.$body.classList.remove('overflow-hidden');
    }
}

export default Navigation;

