import axios from 'axios';
import Mediator from '../../common/scripts/mediator';
import Utils from 'common/scripts/utils';

class ParametricSorting {
    constructor() {
        this.mediator = new Mediator();

        this.activeState = 'parametric-sorting__sort-item_is_active';
        this.reverseState = 'parametric-sorting__sort-item_is_reverse';
        this.activeView = 'parametric-sorting__view_is_active';

        this.containerClass = 'j-parametric-sorting';
        this.viewClass = 'j-parametric-view';
        this.sortClass = 'j-parametric-sort';
        this.flatClass = 'j-parametric-flat';

        this.formData = new FormData();
        // Дополнительно отправляемые данные (например фильтрация)
        this.additionData = new FormData();
    }

    init() {
        this._initElements();
        this._bindEvents();
        this._initFormData();
    }

    _initElements() {
        this.container = document.querySelector(`.${this.containerClass}`);
        this.views = Array.from(document.querySelectorAll(`.${this.viewClass}`));
        this.sorts = Array.from(document.querySelectorAll(`.${this.sortClass}`));
    }

    _bindEvents() {
        this.views.forEach((view) => {
            view.addEventListener('click', (event) => {
                event.preventDefault();

                this._switchView(view);
            });
        });

        this.sorts.forEach((sort) => {
            sort.addEventListener('click', (event) => {
                event.preventDefault();

                this._switchSort(sort);
            });
        });

        // Принимаю доп. данные (FormData)
        this.mediator.subscribe('parametric:sort-send-addition-data', (data) => {
            if (!data) {
                return;
            }

            Utils.mergeFormData(this.additionData, data);
        });

        // Передаю доп. данные в фильтрацию
        this.mediator.subscribe('parametric:filter-query-addition-data', () => {
            this.mediator.publish('parametric:filter-send-addition-data', this.formData);
        });
    }

    _initFormData() {
        this.formData = this._getActiveSort();
    }

    _switchView(target) {
        const type = target.dataset.view;

        if (target.classList.contains(this.activeView)) {
            return;
        }

        this._resetViews();
        target.classList.add(this.activeView);
        this.mediator.publish('parametric:change-view', type);
    }

    _resetViews() {
        this.views.forEach((view) => {
            view.classList.remove(this.activeView);
        });
    }

    _switchSort(target) {
        const sort = target.dataset.sort;
        const data = new FormData();

        this.additionData = new FormData();

        data.append('sort', sort);
        data.append('limit', this._getCountShowFlats());

        if (target.classList.contains(this.reverseState)) {
            target.classList.remove(this.reverseState);

            data.append('turn', 'asc');
        } else if (target.classList.contains(this.activeState)) {
            target.classList.add(this.reverseState);

            data.append('turn', 'desc');
        } else {
            this._resetSortState();
            target.classList.add(this.activeState);

            data.append('turn', 'asc');
        }
        /* eslint-enable */
        this._sendSort(data);
    }

    _sendSort(sendData) {
        const action = this.container.dataset.action;

        this.formData = Utils.copyFormData(sendData);

        this._queryOnAdditionData();
        this._mergeAdditionData(sendData);

        // Включаем лоадер для контентной области
        this.mediator.publish('parametric:run-load-content');

        axios.post(action, sendData)
            .then((response) => {
                const {data} = response.data;

                // Передаем туда где все это будет выводится
                this.mediator.publish('parametric:filtered', data);
                this._setUrl(sendData);
            })
            .catch((error) => { // eslint-disable-line
                console.error(error);
            })
            .then(() => {
                this.additionData = new FormData();

                // Выключаем лоадер контентной области
                this.mediator.publish('parametric:stop-load-content');
            });
    }

    _resetSortState() {
        this.sorts.forEach((sort) => {
            sort.classList.remove(this.activeState);
            sort.classList.remove(this.reverseState);
        });
    }

    _getActiveSort() {
        const data = new FormData();
        const first = 0;
        const target = this.sorts.filter((sort) => {
            return sort.classList.contains(this.activeState);
        })[first];

        if (!target) {
            return data;
        }

        const sort = target.dataset.sort;

        data.append('sort', sort);

        if (target.classList.contains(this.reverseState)) {
            data.append('turn', 'desc');
        } else {
            data.append('turn', 'asc');
        }

        return data;
    }

    _setUrl(formData) {
        const url = new URLSearchParams();

        Array.from(formData).forEach((item) => {
            const keyId = 0;
            const valId = 1;

            url.append(`${item[keyId]}`, item[valId]);
        });

        window.history.pushState(null, null, `?${url.toString()}`);
    }

    _mergeAdditionData(formData) {
        if (!(formData instanceof FormData)) {
            return;
        }

        if (!(this.additionData instanceof FormData)) {
            return;
        }

        Array.from(this.additionData).forEach((item) => {
            const keyId = 0;
            const valId = 1;

            formData.append(`${item[keyId]}`, item[valId]);
        });
    }

    _queryOnAdditionData() {
        // Делаю запрос на получение доп. данных
        this.mediator.publish('parametric:sort-query-addition-data');
    }

    disableSorts() {
        this.sorts.forEach((sort) => {
            sort.classList.add('disable');
        });
    }

    enableSorts() {
        this.sorts.forEach((sort) => {
            sort.classList.remove('disable');
        });
    }

    _getCountShowFlats() {
        this.flats = Array.from(document.querySelectorAll(`.${this.flatClass}`));

        if (!this.flats) {
            return null;
        }

        return this.flats.length;
    }
}

export default ParametricSorting;

