import inputmask from 'inputmask';

class InputMask {
    init() {
        this.setPhoneMask();
    }

    setPhoneMask() {
        const phoneInputs = document.querySelectorAll('input[type="tel"]');
        const phoneMask = '+4\\9 (999)-999-99-99';

        if (!phoneInputs) {
            return;
        }

        inputmask({mask: phoneMask}).mask(phoneInputs);
    }
}

export default InputMask;
