import '../styles/app.scss';

import './common';
import Carousel from '../../components/carousel';
import Construction from '../../components/construction';
import CookiesNotice from '../../components/cookies-notice';
import FlatLine from '../../components/flat-line';
import GContactsMap from '../../components/g-contacts-map';
import GLocationMap from '../../components/g-location-map';
import GMainMap from '../../components/g-main-map';
import HeaderFixed from '../../components/header-fixed';
import Html2pdf from '../../components/html2pdf';
import Mask from '../../components/forms/mask';
import MatchHeight from '../../components/matchHeight';
import Mediator from '../../common/scripts/mediator';
import MiniFilter from '../../components/mini-filter';
import Navigation from '../../components/navigation';
import News from '../../components/news';
import ParametricFilter from '../../components/parametric-filter';
import ParametricFlats from '../../components/parametric-flats';
import ParametricSorting from '../../components/parametric-sorting';
import Popup from '../../components/popup';
import PopupCall from '../../components/popup-call';
import RangeSlider from '../../components/range-slider';
import Slider from '../../components/slider';

const mediator = new Mediator();

// MatchHeight Выравнивание по высоте (заголовков например)
(() => {
    const selectors = ['.j-matchHeight', '.j-mh-flat-title', '.j-mh-flat-content'];
    const initMhAfterChange = (mh) => {
        mediator.subscribe('parametric:after-change-view', (type) => {
            if (type === 'square') {
                mh.init();
            }
        });

        mediator.subscribe('parametric:before-replace-content', () => {
            mh.init();
        });
    };

    selectors.forEach((selector) => {
        const matchHeight = new MatchHeight({element: selector});

        matchHeight.init();

        switch (selector) {
            case '.j-mh-flat-title':
                initMhAfterChange(matchHeight);
                break;
            case '.j-mh-flat-content':
                initMhAfterChange(matchHeight);
                break;
            default: break;
        }
    });
})();

// Маски на поле ввода
(() => {
    const mask = new Mask();

    mask.init();

    mediator.subscribe('openPopup', () => {
        mask.setPhoneMask();
    });
})();

// Инит всех попапов
if (document.querySelector('.link-popup')) {
    const popup = new Popup();

    popup.init({target: '.link-popup'});
}

// Слайдер в попап окне
if (document.querySelector('.j-carousel-zoom')) {
    const popupSlider = new Popup();

    mediator.subscribe('zoomCarousel', (target, takeSlides, currentSlide) => {
        popupSlider.init({
            target       : '.j-carousel-zoom',
            noShowByClick: true,
            template     : 'carouselTemplate',
            slider       : {
                slides : takeSlides,
                current: currentSlide
            }
        });

        popupSlider.makeOpen(target);
    });
}

// Попап "обратный звонок"
if (document.querySelector('#popup-call')) {
    const popupCall = new PopupCall();

    mediator.subscribe('openPopup', (popup) => {
        if (popup.href === 'popup-call') {
            popupCall.init({container: popup.content});
        }
    });
}

// Меню основной
if (document.querySelector('.j-navigation-container')) {
    const navigation = new Navigation();

    navigation.init();
}

/**
 * Range-слайдеры
 */
const rangeSliderSelectors = Array.from(document.querySelectorAll('.j-range-slider-container'));

if (rangeSliderSelectors.length) {
    rangeSliderSelectors.forEach((rangeSliderSelector) => {
        const rangeSlider = new RangeSlider({wrapper: rangeSliderSelector});

        rangeSlider.init();
    });
}

// Карта гугл (главная)
if (document.querySelector('.g-main-map')) {
    const gMainMap = new GMainMap();

    gMainMap.init();
}

// Карта гугл (Расположение)
if (document.querySelector('.g-location-map')) {
    const gLocationMap = new GLocationMap();

    gLocationMap.init();
}

// Карта гугл (Контакты)
if (document.querySelector('.g-contact-map')) {
    const gContactsMap = new GContactsMap();

    gContactsMap.init();
}

// Слайдер на главной
if (document.querySelector('.j-slider')) {
    const slider = new Slider();

    slider.init();
}

// Фильтр на главной
if (document.querySelector('.j-mini-filter')) {
    const miniFilter = new MiniFilter();

    miniFilter.init();

    mediator.subscribe('rangesliderFinish', () => {
        miniFilter.sendData();
    });
}

// Слайдер общий
if (document.querySelector('.j-carousel-init')) {
    const carousel = new Carousel();

    carousel.init();

    mediator.subscribe('openPopup', (popup) => {
        if (popup.href === 'popup-carousel') {
            carousel.init();
        }
    });
}

// Тултип на стр. параметрический
if (document.querySelector('.j-flat-line-tooltip')) {
    const flatLine = new FlatLine();

    flatLine.init();

    mediator.subscribe('parametric:before-replace-content', () => {
        flatLine.init();
    });
}

// Фильтрация параметрического
if (document.querySelector('.j-parametric-filter')) {
    const parametricFilter = new ParametricFilter();

    parametricFilter.init();

    mediator.subscribe('rangesliderFinish', () => {
        parametricFilter.getCountFoundFlats();
    });
}

// Вывод квартир в параметрическом
if (document.querySelector('.j-parametric-content-flats')) {
    const parametricFlats = new ParametricFlats();

    parametricFlats.init();

    mediator.subscribe('parametric:filtered', (data) => {
        parametricFlats.outsideLoadContent(data);
    });

    mediator.subscribe('parametric:change-view', (type) => {
        parametricFlats.changeView(type);
    });

    mediator.subscribe('parametric:run-load-content', () => {
        parametricFlats.runLoad();
    });

    mediator.subscribe('parametric:stop-load-content', () => {
        parametricFlats.stopLoad();
    });
}

// Сортировка в параметрическом
if (document.querySelector('.j-parametric-sorting')) {
    const parametricSorting = new ParametricSorting();

    parametricSorting.init();

    mediator.subscribe('parametric:run-load-content', () => {
        parametricSorting.disableSorts();
    });

    mediator.subscribe('parametric:stop-load-content', () => {
        parametricSorting.enableSorts();
    });
}


if (document.querySelector('.j-header-fixed-main')) {
    const headerFixed = new HeaderFixed();

    headerFixed.init();

    mediator.subscribe('visual:open-header', () => {
        headerFixed.openHeader();
    });
}

if (document.querySelector('.j-news-main')) {
    new News().init();
}

if (document.querySelector('.j-construction-main')) {
    new Construction().init();
}

// Скачивание pdf
if (document.querySelector('.j-download-pdf')) {
    new Html2pdf({target: '.j-download-pdf'}).init();
}

// Уведомление об использовании куки.
const cookiesWrapper = document.querySelector('.j-cookies-notice');

if (cookiesWrapper) {
    const cookies = new CookiesNotice();

    cookies.init(cookiesWrapper);
}
