import axios from 'axios';
import GMaps from '../../components/map';
import Mediator from '../../common/scripts/mediator';

class GLocationMap {
    constructor() {
        this.mediator = new Mediator();
        this.map = new GMaps();

        this.mapClass = 'j-gmap';
        this.formInfrClass = 'j-location-infr-form';
        this.infrClass = 'j-location-infr';
        this.infrAllClass = 'j-location-infr-all';
        this.circleClass = 'j-location-circle';

        this.circlePaint = false;
    }

    init() {
        this._initElements();
        this._loadData();
        this._bindEvents();
    }

    _initElements() {
        this.mapContainer = document.querySelector(`.${this.mapClass}`);
        this.formInfr = document.querySelector(`.${this.formInfrClass}`);
        this.infrs = Array.from(document.querySelectorAll(`.${this.infrClass}`));
        this.infrAll = document.querySelector(`.${this.infrAllClass}`);
        this.circle = document.querySelector(`.${this.circleClass}`);
    }

    _bindEvents() {
        this.infrAll.addEventListener('click', (event) => {
            if (this.infrAll.checked === true) {
                this.formInfr.reset();
                this._showAllMarker();
            } else {
                event.preventDefault();
            }
        });

        this.infrs.forEach((infr) => {
            infr.addEventListener('click', () => {
                if (this._checkAllUnchecked()) {
                    this._showAllMarker();

                    return;
                }

                const activeTypes = this._getActiveInfrastructures();

                this._hideAllMarker();

                activeTypes.forEach((type) => {
                    const markers = this._findMarkersByType(type);

                    markers.forEach((marker) => {
                        this._showMarker(marker);
                    });
                });
            });
        });
    }

    _loadData() {
        const action = this.mapContainer.dataset.action;

        axios.post(action)
            .then((response) => {
                const {data} = response.data;

                this.dataMap = data;
                this._initMap(data);
            })
            .catch((error) => { // eslint-disable-line dot-notation
                console.error(error);
            });
    }

    _initMap(data) {
        if (!this.mapContainer) {
            return;
        }

        const defaultSetting = {
            key              : 'AIzaSyDVd10MSy8hk7HjbSxNhkYMyBdmvpGaUyE',
            target           : this.mapContainer,
            fullscreenControl: false,
            customZoom       : true
        };

        const setting = Object.assign(defaultSetting, data);

        if (data.infrastructure) {
            data.infrastructure.forEach((infra) => {
                data.markers.push(infra);
            });
        }


        this.map.init(setting, () => {
            this._insertCircle(data);
            this.circle.addEventListener('click', (event) => {
                event.preventDefault();

                // this._insertCircle(data);
            });
        });
    }

    _checkAllUnchecked() {
        let allUncheck = true;

        this.infrs.forEach((infr) => {
            if (infr.checked === true) {
                allUncheck = false;
            }
        });

        this.infrAll.checked = allUncheck;

        return allUncheck;
    }

    _getActiveInfrastructures() {
        const types = [];

        this.infrs.forEach((infr) => {
            if (!infr.checked) {
                return;
            }

            let type = infr.getAttribute('id');

            if (!type) {
                type = infr.getAttribute('value');
            }

            types.push(type);
        });

        return types;
    }

    _findMarkersByType(type) {
        return this.map.gMarkers.filter((marker) => {
            return marker.type === type;
        });
    }

    _hideAllMarker() {
        this.map.gMarkers.forEach((marker) => {
            if (!marker.type) {
                return;
            }

            marker.setVisible(false);
        });
    }

    _showAllMarker() {
        this.map.gMarkers.forEach((marker) => {
            if (!marker.type) {
                return;
            }

            marker.setVisible(true);
        });
    }

    _showMarker(marker) {
        marker.setVisible(true);
    }

    _insertCircle(data) {
        if (this.circlePaint) {
            return;
        }

        const options = [{
            center: data.circle.center,
            radius: data.circle.radius,
            stroke: {
                color  : 'white',
                opacity: 0.5,
                weight : 2
            },
            fill: {
                color  : 'white',
                opacity: 0.6
            }
        }];

        this.map.setRadiuses(options);
        this.circlePaint = true;
    }
}

export default GLocationMap;
