/**
 * @version 1.0alpha
 * @link https://kelnik.gitbooks.io/kelnik-documentation/content/front-end/components/tooltip.html documentation
 */
import $ from 'jquery';
import Utils from '../../common/scripts/utils';
import visualFlatTmpl from './templates/visual-flat.twig';
import visualTmpl from './templates/visual.twig';

class Tooltip {
    constructor() {
        /**
         * цель, на которой появится тултип
         * @type {Node}
         */
        this.target = null;

        /**
         * DOM нода тултипа
         * @type {null}
         */
        this.tooltip = null;

        this.modify = '';

        /**
         * Шаблон
         */
        this.template = '';

        /**
         * Позиция по x оси
         * @type {number}
         */
        this.x = 0;

        /**
         * Позиция по y оси
         * @type {number}
         */
        this.y = 0;

        /**
         * Расположение тултипа относительно цели
         * over - над целью
         * under - под целью
         * left - слева от цели
         * right - справа от цели
         * @type {string}
         */
        this.direction = 'under';
        this.defDirection = this.direction;

        /**
         * Смещение тултипа от цели
         * чем больше цифра, тем дальше тултип будет от цели
         * @type {number}
         */
        this.offset = 20;

        /**
         * Данные для текущего тултипа.
         * @type {object}
         */
        this.currentData = {};

        /**
         * Идентификатор текущего тултипа.
         * @type {number}
         */
        this.id = 0;
    }


    /**
     * Метод инициализирует модуль.
     * @param {object} options - опции из вне. Пока тестировалось только на визуальном
     * @public
     */
    init(options) {
        this.options = options;
        this.target = options.target;
        this.data = options.data;
        this.modify = options.modify;
        // Закрыть тултип при клике на таргет - используется на визуальном
        this.closeClick = options.closeClick;

        this._setTemplate(options.template);
        this._bindEvents();
    }

    /**
     * Метод устанавливает шаблон исходя из настроек
     * @param {string} template - опция полученная из вне.
     * @private
     */
    _setTemplate(template) {
        switch (template) {
            case 'visual':
                this.template = visualTmpl;
                break;
            case 'visual-flat':
                this.template = visualFlatTmpl;
                break;
            default:
                this.template = template;
                break;
        }
    }

    /**
     * Вешаем слушателей на нужные контролы
     * @private
     */
    _bindEvents() {
        if (this.target.init) {
            // удаляем обработчики события, чтобы запилить новый с новыми данными
            $(this.target).off();
        }

        this.target.init = true;

        $(this.target).on('mouseover', (event) => {
            this.direction = this.defDirection;

            this._getMouseCoords(event);
            this._insertTemplate(event);
            this._isHoverState(event);

            this.target.addEventListener('mousemove', (moveevent) => {
                this._getMouseCoords(moveevent);
                this._setPosition();
            });
        });

        this.target.addEventListener('mouseout', (event) => {
            Utils.removeElement(this.tooltip);
            this._isUnHoverState(event);
        });

        if (this.closeClick) {
            this.target.addEventListener('click', () => {
                this.tooltip.style.display = 'none';
            });
        }
    }

    /**
     * Метод получает координаты мышки.
     * @param {object} event - cобытие.
     * @private
     */
    _getMouseCoords(event) {
        this.mouseCoords = {
            X: event.pageX,
            Y: event.pageY
        };

        this.mouseCoordsWindow = {
            X: event.clientX,
            Y: event.clientY
        };
    }

    /**
     * Создает и вставляет тултип на страницу.
     * @param {object} event - событие.
     * @private
     */
    _insertTemplate() {
        const openDelay = 100;

        if (typeof this.template !== 'function') {
            console.error(`Шаблона '${this.template}' для тултипа не существует!`);

            return;
        }

        Utils.insetContent(document.body, this.template(this.data));
        this.tooltip = document.querySelector('.j-tooltip');

        setTimeout(() => {
            this.tooltip.classList.add('is-visible');
        }, openDelay);

        this._setPosition();
    }


    /**
     * Устанавливает данные для тултипа исходя из его id и массива всех данных;
     * @param {object} event - событие.
     * @private
     */
    _setCurrentData(event) {
        // Если есть массив с данными, то берем оттуда (визуальный), если нет то из дата-атрибута
        if (this.data) {
            this.id = Number(event.target.id);

            this.currentData = this.data.find((element) => {
                const id = Number(element.id);

                return id === this.id ? element : false;
            });
        } else {
            this.currentData.tooltip = Utils.getDataSet(event.target);
        }
    }

    /**
     * Метод позицианирует тултип на странице.
     * @private
     */
    _setPosition() {
        const x = this._x();
        const y = this._y();

        this._checkBorders();
        this._position(x, y);
    }

    /**
     * Метод проверяет не выходит ли тултип за рамки экрана, и если нужно меняет его направление.
     * ПРОВЕРЯЛОСЬ ТОЛЬКО НА РЕЖИМЕ "over".
     * @private
     */
    _checkBorders() {
        const halfWidth = 2;
        let mouseCoord = 0;
        let tooltipCoord = 0;

        // Не влезает по верхнему краю.
        const top = () => {
            mouseCoord = this.mouseCoordsWindow.Y;
            tooltipCoord = this.tooltip.offsetHeight + this.offset;

            return mouseCoord < tooltipCoord;
        };

        // Не влезает по нижнему краю.
        const down = () => {
            mouseCoord = this.mouseCoordsWindow.Y;
            tooltipCoord = this.tooltip.offsetHeight + this.offset;

            return (mouseCoord + tooltipCoord) > window.innerHeight;
        };

        // Не влезает по правому краю.
        const right = () => {
            mouseCoord = window.innerWidth - this.mouseCoordsWindow.X;
            tooltipCoord = (this.tooltip.offsetWidth / halfWidth) + this.offset;

            return mouseCoord < tooltipCoord;
        };

        // Не влезает по левому краю.
        const left = () => {
            mouseCoord = this.mouseCoordsWindow.X;
            tooltipCoord = (this.tooltip.offsetWidth / halfWidth) - this.offset;

            return mouseCoord < tooltipCoord;
        };

        if (top()) {
            this.direction = 'under';
            this.tooltip.classList.add('is-under');
        } else if (down()) {
            this.direction = 'over';
            this.tooltip.classList.remove('is-under');
        }

        if (right()) {
            this.direction = 'left';
        }

        if (left()) {
            this.direction = 'right';
        }
    }

    /**
     * Определям X координату тултипа
     * @return {number} - y координата тултипа
     * @private
     */
    _x() {
        let x = 0;
        const halfWidth = 2;

        switch (this.direction) {
            case 'over':
                x = this.mouseCoords.X - (this.tooltip.offsetWidth / halfWidth);
                break;
            case 'under':
                x = this.mouseCoords.X - (this.tooltip.offsetWidth / halfWidth);
                break;
            case 'left':
                x = this.mouseCoords.X - this.tooltip.offsetWidth - this.offset;
                break;
            case 'right':
                x = this.mouseCoords.X + this.offset;
                break;
            default:
                x = this.mouseCoords.X - (this.tooltip.offsetWidth / halfWidth);
                break;
        }

        return x;
    }

    /**
     * Определям Y координату тултипа
     * @return {number} - y координата тултипа
     * @private
     */
    _y() {
        let y = 0;
        const halfHeight = 2;

        switch (this.direction) {
            case 'over':
                y = this.mouseCoords.Y - this.tooltip.offsetHeight - this.offset;
                break;
            case 'under':
                y = this.mouseCoords.Y + this.offset;
                break;
            case 'left':
                y = this.mouseCoords.Y - (this.tooltip.offsetHeight / halfHeight);
                break;
            case 'right':
                y = this.mouseCoords.Y - (this.tooltip.offsetHeight / halfHeight);
                break;
            default:
                y = this.mouseCoords.Y - this.tooltip.offsetHeight - this.offset;
                break;
        }

        return y;
    }

    /**
     * Позиционирование элемента
     * @param {Number/String} x - координаты цели по x
     * @param {Number/String} y - координаты цели по y
     * @private
     */
    _position(x, y) {
        this.tooltip.style.top = `${parseInt(y, 10)}px`;
        this.tooltip.style.left = `${parseInt(x, 10)}px`;
    }

    /**
     * Метод симулирует состояние ховера на таргете.
     * @param {object} event - событие mouseover
     * @private
     */
    _isHoverState(event) {
        const target = event.target;

        target.classList.add('is-hover');
    }

    /**
     * Метод симулирует потерю ховера на таргете.
     * @param {object} event - событие mouseout
     * @private
     */
    _isUnHoverState(event) {
        const target = event.target;

        target.classList.remove('is-hover');
    }
}

export default Tooltip;
