class HeaderFixed {
    constructor() {
        this.headerClass = 'j-header-fixed-main';
        this.closeClass = 'j-header-fixed-close';
        this.activeClass = 'header-fixed-show';
    }

    init() {
        this._initElements();
        this._bindEvents();
    }

    _initElements() {
        this.header = document.querySelector(`.${this.headerClass}`);
        this.close = document.querySelector(`.${this.closeClass}`);
    }

    _bindEvents() {
        this.close.addEventListener('click', () => {
            this._closeHeader();
        });
    }

    _closeHeader() {
        this.header.classList.remove(this.activeClass);
    }

    openHeader() {
        this.header.classList.add(this.activeClass);
    }
}

export default HeaderFixed;

