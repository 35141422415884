import HammerJs from 'hammerjs';

class Slider {
    constructor() {
        this.slider = 'j-slider';
        this.arrowLeft = 'j-slide-left';
        this.arrowRight = 'j-slide-right';
        this.slide = 'slider__slide';
        this.image = 'slider__image';
        this.timeline = 'j-slider-timeline';

        // Анимация скрытия слайда влево
        this.hideToLeft = 'slider__slide-hide-left';
        // Анимация скрытия слайда вправо
        this.hideToRight = 'slider__slide-hide-right';
        // Дает сладу верхний z-index
        this.toActive = 'slider__slide-active';
        // Дает сладу второстепенный z-index
        this.toNext = 'slider__slide-next';
        // Активность анимации (не трогать)
        this.runAnimate = false;
        // Длительность анимации | поменял тут поменяй и в стилях
        this.duration = 1000;
        // Интервал перехода на новый слайд (false для отключения)
        this.autoPlay = 8000;
        this.timerSlide = null;
        this.timerLine = null;
    }

    init() {
        this._initElements();
        this._bindEvents();
        this._setActiveSlide();
        this._setBackgroundSlide();
        this._initHammer();
        this._startAutoPlay();
    }

    _initElements() {
        this.$slider = document.querySelector(`.${this.slider}`);
        this.$slides = Array.from(this.$slider.querySelectorAll(`.${this.slide}`));
        this.$arrowLeft = this.$slider.querySelector(`.${this.arrowLeft}`);
        this.$arrowRight = this.$slider.querySelector(`.${this.arrowRight}`);
        this.$timeline = this.$slider.querySelector(`.${this.timeline}`);

        this.active = 0;
        this.next = this._getNextSlideId();
        this.prev = this._getPrevSlideId();
    }

    _bindEvents() {
        this.$arrowLeft.addEventListener('click', () => {
            this._prevSlide();
        });

        this.$arrowRight.addEventListener('click', () => {
            this._nextSlide();
        });

        this.$slider.addEventListener('mouseenter', () => {
            this.mouseEnter = true;
            this._pauseAutoPlay();
        });

        this.$slider.addEventListener('mouseleave', () => {
            this.mouseEnter = false;
            this._runAutoPlay();
        });
    }

    _initHammer() {
        const mc = new HammerJs(this.$slider);

        mc.on('swipeleft', () => {
            this._nextSlide();
        });

        mc.on('swiperight', () => {
            this._prevSlide();
        });
    }

    _setActiveSlide() {
        this.$slides[this.active].classList.add(this.toActive);
    }

    _setBackgroundSlide() {
        this.$slides.forEach(($slide) => {
            const $image = $slide.querySelector(`.${this.image}`);
            const src = $image.dataset.src;

            $image.style.backgroundImage = `url(${src})`;
        });
    }

    _refreshSlidesId() {
        this.next = this._getNextSlideId();
        this.prev = this._getPrevSlideId();
    }

    _getNextSlideId(id = this.active) {
        const one = 1;
        const zero = 0;

        if (!this.$slides.length) {
            return false;
        }

        return id >= this.$slides.length - one ? zero : id + one;
    }

    _getPrevSlideId(id = this.active) {
        const one = 1;
        const zero = 0;

        if (!this.$slides.length) {
            return false;
        }

        return this.active <= zero ? this.$slides.length - one : id - one;
    }

    _nextSlide() {
        this._switchSlide(this.next, this.hideToLeft);
    }

    _prevSlide() {
        this._switchSlide(this.prev, this.hideToRight);
    }

    /**
     * Переключает на следующий слайд по id
     * @param {number} id - id нужного следующего слайда
     * @param {string} turnAnimate - класс анимации для перехода
     */
    _switchSlide(id, turnAnimate = this.hideToLeft) {
        if (this.runAnimate) {
            return;
        }

        const minSlides = 1;

        if (this.$slides.length <= minSlides) {
            return;
        }

        this.runAnimate = true;
        let turnAnimateShow = 'slider__slide-show-right';

        if (!this.mouseEnter) {
            this._pauseAutoPlay();
        }

        if (turnAnimate === this.hideToLeft) {
            turnAnimateShow = 'slider__slide-show-left';
        }

        this.$slides[id].classList.add(this.toNext);
        this.$slides[this.active].classList.add(turnAnimate);
        this.$slides[id].classList.add(turnAnimateShow);

        setTimeout(() => {
            this.$slides[this.active].classList.remove(this.toActive);
            this.$slides[this.active].classList.remove(turnAnimate);
            this.$slides[id].classList.remove(turnAnimateShow);

            this.$slides[id].classList.add(this.toActive);
            this.$slides[id].classList.remove(this.toNext);

            this.active = id;

            this._refreshSlidesId();

            if (!this.mouseEnter) {
                this._runAutoPlay();
            }

            this.runAnimate = false;
        }, this.duration);
    }

    _startAutoPlay() {
        const minTime = 1000;

        if (!this.autoPlay) {
            return;
        }

        if (this.autoPlay < minTime) {
            console.error('Минимальный интервал перехода слайда 3000ms');
            this.autoPlay = minTime;
        }

        this._runAutoPlay();
    }

    _runAutoPlay() {
        clearTimeout(this.timerSlide);

        this._runTimeLine();

        this.timerSlide = setTimeout(() => {
            this._nextSlide();
        }, this.autoPlay);
    }

    _pauseAutoPlay() {
        clearTimeout(this.timerSlide);

        this._pauseTimeLine();
    }

    _runTimeLine() {
        clearInterval(this.timerLine);

        let percent = 0;
        const maxPercent = 100;
        const one = 1;

        this.timerLine = setInterval(() => {
            percent = percent + one;
            this.$timeline.style.width = `${percent}%`;
        }, this.autoPlay / maxPercent);
    }

    _pauseTimeLine() {
        clearInterval(this.timerLine);
    }
}

export default Slider;

