import axios from 'axios';
import Utils from 'common/scripts/utils';

class PopupCall {
    constructor() {
        this.formSelector = '.j-form-call';
        this.submitSelector = '.j-popup-call-submit';
        this.policySelector = '.j-popup-call-policy';
        this.errorRequestSelector = '.j-form-call-error';

        this.submitDisableClass = 'disabled';

        this.validElementClass = 'input-validate-error';
        this.errorInputClass = 'input-error';
        this.successMessageClass = 'input-success-message';
    }

    init(options) {
        this.container = options.container;


        this._initElements();
        this._bindEvents();
    }

    _initElements() {
        this.form = this.container.querySelector(this.formSelector);
        this.submit = this.container.querySelector(this.submitSelector);
        this.policy = this.container.querySelector(this.policySelector);
        this.errorRequest = this.container.querySelector(this.errorRequestSelector);
    }

    _bindEvents() {
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();

            this._sendMail(event.target);
        });

        this.policy.addEventListener('change', () => {
            if (this.policy.checked) {
                this._enableSubmit();
            } else {
                this._disableSubmit();
            }
        });
    }

    _sendMail(target) {
        const action = target.getAttribute('action');
        const method = target.getAttribute('method').toLowerCase() || 'get';
        const sendData = new FormData(target);

        if (!action) {
            console.error('Не указал атрибут "action" для формы', target);

            return;
        }

        axios[method](action, sendData)
            .then((response) => {
                const {data} = response.data;

                if (data.validMessage) {
                    if (Object.keys(data.validMessage).length && data.validMessage.constructor === Object) {
                        this._removeErrorFromInputs();
                        this._removeValidationElements();
                        this._displayValidation(data.validMessage);

                        return;
                    }
                } else {
                    this._removeErrorFromInputs();
                    this._removeValidationElements();
                }

                if (data.status) {
                    this._successSend(data.successMessage);
                } else {
                    this._showError(data.error);
                }
            })
            .catch((error) => { // eslint-disable-line
                console.error(error);
            });
    }

    _removeValidationElements() {
        const validElements = Array.from(this.form.querySelectorAll(`.${this.validElementClass}`));

        validElements.forEach((element) => {
            Utils.removeElement(element);
        });
    }

    _removeErrorFromInputs() {
        const errorInputs = Array.from(this.form.querySelectorAll(`.${this.errorInputClass}`));

        errorInputs.forEach((element) => {
            element.classList.remove(this.errorInputClass);
        });
    }

    _inputToError(input) {
        input.classList.add(this.errorInputClass);
    }

    _displayValidation(messages) {
        const createValidEl = (message, input) => {
            const errorEl = document.createElement('span');

            errorEl.classList.add(this.validElementClass);
            errorEl.innerText = message;

            input.after(errorEl);
        };

        for (const key in messages) {
            if (Object.prototype.hasOwnProperty.call(messages, key)) {
                const input = this.form.querySelector(`input[name="${key}"]`);

                if (!input) {
                    continue; // eslint-disable-line
                }

                this._inputToError(input);

                if (typeof messages[key] === 'string') {
                    createValidEl(messages[key], input);

                    continue; // eslint-disable-line
                }

                messages[key].forEach((message) => {
                    if (!message) {
                        return;
                    }

                    createValidEl(message, input);
                });
            }
        }
    }

    _successSend(message) {
        Utils.clearHtml(this.form);
        Utils.insetContent(this.form, `<div class="${this.successMessageClass}">${message}</div>`);
    }

    _showError(error) {
        if (!this.errorRequest) {
            return;
        }

        Utils.clearHtml(this.errorRequest);
        Utils.insetContent(this.errorRequest, `${error}`);
    }

    _disableSubmit() {
        this.submit.classList.add(this.submitDisableClass);
        this.submit.disabled = true;
    }

    _enableSubmit() {
        this.submit.classList.remove(this.submitDisableClass);
        this.submit.disabled = false;
    }
}

export default PopupCall;

