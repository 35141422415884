import Tooltip from '../tooltip';
import tooltipTemplate from './templates/tooltipTemplate.twig';

class FlatLine {
    constructor() {
        this.imgClass = 'j-flat-line-tooltip';
    }

    init() {
        this._initElements();
        this._initTooltips();
    }

    _initElements() {
        this.images = Array.from(document.querySelectorAll(`.${this.imgClass}`));
    }

    _initTooltips() {
        this.images.forEach((image) => {
            if (image.dataset.init) {
                return;
            }

            image.dataset.init = 'true';

            const tooltip = new Tooltip();
            const urlImg = image.dataset.src;

            tooltip.init({
                target    : image,
                direction : 'under',
                closeClick: true,
                template  : tooltipTemplate,
                data      : {url: urlImg}
            });
        });
    }
}

export default FlatLine;

