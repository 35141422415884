import 'url-search-params-polyfill';
import axios from 'axios';
import Mediator from '../../common/scripts/mediator';
import tamplateFlats from './templates/flats.twig';
import Utils from '../../common/scripts/utils';

class ParametricFlats {
    constructor() {
        this.mediator = new Mediator();

        this.mainClass = 'j-parametric-content-flats';
        this.containerClass = 'j-parametric-container';
        this.flatClass = 'j-parametric-flat';
        this.loadMoreClass = 'j-parametric-load-more';
        this.searchTextClass = 'j-parametric-search-text';

        this.squareViewClass = 'parametric-flats__content_show_square';
        this.mainLoadClass = 'parametric-flats_is_load';

        // Дополнительно отправляемые данные (например сортировка)
        this.additionData = new FormData();
    }

    init() {
        this._initElements();
        this._bindEvents();
    }

    _initElements() {
        this.main = document.querySelector(`.${this.mainClass}`);
        this.container = document.querySelector(`.${this.containerClass}`);
        this.flats = document.querySelectorAll(`.${this.flatClass}`);
        this.loadMore = document.querySelector(`.${this.loadMoreClass}`);
        this.searchText = document.querySelector(`.${this.searchTextClass}`);
    }

    _bindEvents() {
        this.loadMore.addEventListener('click', (event) => {
            event.preventDefault();

            this._loadMore();
        });

        // Принимаю данные доп. данные (FormData)
        this.mediator.subscribe('parametric:filter-send-addition-data', (data) => {
            if (!data) {
                return;
            }

            Utils.mergeFormData(this.additionData, data);
        });

        this.mediator.subscribe('parametric:sort-send-addition-data', (data) => {
            if (!data) {
                return;
            }

            Utils.mergeFormData(this.additionData, data);
        });
    }

    _loadMore() {
        const sendData = new FormData();
        const action = this.loadMore.dataset.action;

        this.additionData = new FormData();

        sendData.append('showFlats', this._getCountShowFlats());
        this._queryOnAdditionData();
        this._mergeAdditionData(sendData);

        this._disableLoadMore();

        axios.post(action, sendData)
            .then((response) => {
                const {data} = response.data;

                this._insertAfterContent(data);
                this._actionAfterTakeData(data);
            })
            .catch((error) => { // eslint-disable-line
                console.error(error);
            })
            .then(() => {
                this._enableLoadMore();
                this.additionData = new FormData();
            });
    }

    outsideLoadContent(data) {
        this._replaceContent(data);
        this._actionAfterTakeData(data);
    }

    // Все что происходит после получения данных (вставки или замены контента) (из вне или из запросы)
    _actionAfterTakeData(data) {
        this._replaceTextLoadMore(data);
        this._checkLoadMore(data.showMore);
        this._replaceSearchText(data.searchText);

        // Сообщаем что элементы обновились
        this.mediator.publish('parametric:before-replace-content');
    }

    _replaceContent(data) {
        Utils.clearHtml(this.container);
        this._insertAfterContent(data);
    }

    _insertAfterContent(data) {
        Utils.insetContent(this.container, tamplateFlats(data));
    }

    _replaceTextLoadMore(data) {
        Utils.clearHtml(this.loadMore);
        Utils.insetContent(this.loadMore, data.searchButtonText);
    }

    _getCountShowFlats() {
        this.flats = document.querySelectorAll(`.${this.flatClass}`);

        return this.flats.length;
    }

    _queryOnAdditionData() {
        // Делаю запросы на получение доп. данных
        this.mediator.publish('parametric:filter-query-addition-data');
        this.mediator.publish('parametric:sort-query-addition-data');
    }

    _mergeAdditionData(formData) {
        if (!(formData instanceof FormData)) {
            return;
        }

        if (!(this.additionData instanceof FormData)) {
            return;
        }

        Array.from(this.additionData).forEach((item) => {
            const keyId = 0;
            const valId = 1;

            formData.append(`${item[keyId]}`, item[valId]);
        });
    }

    changeView(type) {
        switch (type) {
            case 'line':
                this.container.classList.remove(this.squareViewClass);
                break;
            case 'square':
                this.container.classList.add(this.squareViewClass);
                break;
            default:
                console.error(`type "${type}" not found!`);
        }

        this.mediator.publish('parametric:after-change-view', type);
    }

    _disableLoadMore() {
        this.loadMore.classList.add('disabled');
    }

    _enableLoadMore() {
        this.loadMore.classList.remove('disabled');
    }

    _showLoadMore() {
        Utils.show(this.loadMore);
    }

    _hideLoadMore() {
        Utils.hide(this.loadMore);
    }

    runLoad() {
        this.main.classList.add(this.mainLoadClass);
    }

    stopLoad() {
        this.main.classList.remove(this.mainLoadClass);
    }

    _checkLoadMore(showMore) {
        if (showMore) {
            this._showLoadMore();
        } else {
            this._hideLoadMore();
        }
    }

    _replaceSearchText(searchText) {
        if (typeof searchText !== 'string') {
            return;
        }

        this.searchText.textContent = searchText;
    }
}

export default ParametricFlats;

