class GContactsMap {
    constructor() {
        this.mapClass = 'j-gmap';
        this.mapContactClass = 'j-contact-map';
    }

    init() {
        this._initElements();
        this._initMaps();
    }

    _initElements() {
        const first = 0;

        this.mapContact = Array.from(document.querySelectorAll(`.${this.mapContactClass}`));
        this.key = this.mapContact[first].dataset.key;
    }

    _initMaps() {
        this.initMapContact();
        this._scriptGoogleMap();
    }

    _getData(mapContact) {
        const coordArray = mapContact.dataset.center.split(',');
        const coord = this.coordsArrayConvertToGoogleCoords(coordArray);

        return {
            noScript: mapContact.dataset.noscript,
            key     : mapContact.dataset.key,
            center  : coord,
            zoom    : Number(mapContact.dataset.zoom),
            markers : [{
                coords: coord,
                icon  : mapContact.dataset.icon
            }]
        };
    }

    initMapContact() {
        window.initMapContact = () => {
            this.mapContact.forEach((map) => {
                const data = this._getData(map);

                this._initMap(data, map);
            });
        };
    }

    _scriptGoogleMap() {
        const script = document.createElement('script');

        script.async = true;
        script.defer = true;
        script.type = 'text/javascript';
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.key}&callback=initMapContact`;

        document.body.appendChild(script);
    }

    _initMap(data, mapContact) {
        const mapContainer = mapContact.querySelector(`.${this.mapClass}`);

        if (!mapContainer) {
            return;
        }

        const defaultSetting = {
            key              : 'AIzaSyCfTtRd2021fPEAoyR2GJUSVfCXoEG4p5E',
            target           : mapContainer,
            fullscreenControl: false,
            customZoom       : true
        };

        const setting = Object.assign(defaultSetting, data);
        const map = new google.maps.Map(mapContact, setting); // eslint-disable-line

        this._initMarkers(setting.markers, map, mapContact);
    }

    _initMarkers(markers, map) {
        if (!markers) {
            return;
        }

        for (let i = 0; i < markers.length; i++) {
            this.createMarker(markers[i], map);
        }
    }

    /**
     * Превращает пользовательский макер в гуглмаркер
     * @param {Object} marker - пользовательский маркер
     * @param {Object} gmap - Карта гугла
     * @returns {Object} gmarker - возвращает гугломаркер
     */
    createMarker(marker, gmap) {
        const defaultSizeMarker = 26;
        const minMarkerSize = 2;
        const width = 0;
        const height = 1;
        let gMarker = {};
        const half = 2;

        // если в массиве пришли строки, приводим их к integer
        const toInteger = function(array) {
            return array.map((item) => {
                return parseInt(item, 10);
            });
        };

        // проверка на правильность данных из marker.sizes = Array [Number, Number]
        const sizes = Array.isArray(marker.sizes) && marker.sizes.length === minMarkerSize ?
            toInteger(marker.sizes) :
            [defaultSizeMarker, defaultSizeMarker];

        // проверка на правильность данных из marker.icon = String
        let isIcon = typeof marker.icon === 'string' ? marker.icon : '/icons/map/media/markers/marker.svg';
        const match = isIcon.match(/^\.|\.svg$|\.gif$|.jpg$|\.png$/iu);
        const svg = 0;

        if (match[svg] === '.svg') {
            isIcon = {
                url       : isIcon,
                scaledSize: new google.maps.Size(sizes[width], sizes[height]), // eslint-disable-line
                anchor    : new google.maps.Point(sizes[width] / half, sizes[width] / half) // eslint-disable-line
            };
        }

        const markerOptions = {
            position : marker.coords,
            map      : gmap,
            animation: google.maps.Animation.DROP, // eslint-disable-line
            optimized: false,
            icon     : isIcon
        };

        Object.assign(markerOptions, marker);
        gMarker = new google.maps.Marker(markerOptions); // eslint-disable-line

        return gMarker;
    }

    coordsArrayConvertToGoogleCoords(coords) {
        const lat = 0;
        const lng = 1;

        return {
            lat: parseFloat(coords[lat]),
            lng: parseFloat(coords[lng])
        };
    }
}

export default GContactsMap;
