class Construction {
    constructor() {
        this.mainSelector = '.j-construction-main';
        this.linkSelector = '.j-construction-link';
        this.tabSelector = '.j-construction-tab';

        this.activeTab = 'b-construction__tab-active';
        this.activeLink = 'b-construction__switch-active';
    }

    init() {
        this._initElements();
        this._bindEvents();
    }

    _initElements() {
        this.main = document.querySelector(this.mainSelector);
        this.links = Array.from(this.main.querySelectorAll(this.linkSelector));
        this.tabs = Array.from(this.main.querySelectorAll(this.tabSelector));
    }

    _bindEvents() {
        this.links.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault();

                this._deactivateAllLink();
                this._activeLink(link);
                this._switchTab(link);
            });
        });
    }

    _switchTab(link) {
        const tabId = link.dataset.tab;
        const tab = this.main.querySelector(`#${tabId}`);

        if (tab.classList.contains(this.activeTab)) {
            return;
        }

        this._deactivateAllTab();
        this._activeTab(tab);
    }

    _activeTab(tab) {
        tab.classList.add(this.activeTab);
    }

    _activeLink(link) {
        link.classList.add(this.activeLink);
    }

    _deactivateAllTab() {
        this.tabs.forEach((tab) => {
            tab.classList.remove(this.activeTab);
        });
    }

    _deactivateAllLink() {
        this.links.forEach((link) => {
            link.classList.remove(this.activeLink);
        });
    }
}

export default Construction;

