import axios from 'axios';
import GMaps from '../../components/map';
import Mediator from '../../common/scripts/mediator';
import templateBalloon from './templates/balloon.twig';
import Utils from '../../common/scripts/utils';

class GMainMap {
    constructor() {
        this.mediator = new Mediator();
        this.map = new GMaps();

        this.mapClass = 'j-gmap';
        this.balloonContainerClass = 'j-balloon-container';
        this.balloonShowClass = 'gmap-info_is-visible';

        this.tableLandscape = 960;
    }

    init() {
        this._initElements();
        this._loadData();
        this._bindEvents();
        // this._hideBalloon();
    }

    _initElements() {
        this.$map = document.querySelector(`.${this.mapClass}`);
        this.$balloonContainer = document.querySelector(`.${this.balloonContainerClass}`);
    }

    _bindEvents() {
        this.mediator.subscribe('map:marker-click', (data) => {
            const delay = 500;

            if (!data.balloon) {
                return;
            }

            if (this._checkVisibleBalloon()) {
                this._hideBalloon();

                setTimeout(() => {
                    this._replaceHtmlBalloon(data);
                    this._showBalloon();
                }, delay);
            } else {
                this._replaceHtmlBalloon(data);
                this._showBalloon();
            }
        });
    }

    _loadData() {
        const action = this.$map.dataset.action;

        axios.post(action)
            .then((response) => {
                const first = 0;
                const {data} = response.data;
                const {markers} = data;

                this.dataMap = data;
                this._initMap(data);
                this._replaceHtmlBalloon(markers[first]);
                this._showBalloon();
            })
            .catch((error) => { // eslint-disable-line dot-notation
                console.error(error);
            });
    }

    _initMap(data) {
        if (!this.$map) {
            return;
        }

        const defaultSetting = {
            key              : 'AIzaSyCfTtRd2021fPEAoyR2GJUSVfCXoEG4p5E',
            target           : this.$map,
            fullscreenControl: false,
            customZoom       : true
        };

        const setting = Object.assign(defaultSetting, data);

        this.map.init(setting, () => {
            if (window.innerWidth < this.tableLandscape) {
                this.map.setCenter(this.dataMap.centerSecond);
            }

            this._setCenterMapByWidth();
        });
    }

    _replaceHtmlBalloon(data) {
        Utils.clearHtml(this.$balloonContainer);
        Utils.insetContent(this.$balloonContainer, templateBalloon(data));
    }

    _showBalloon() {
        const delayRender = 10;

        Utils.show(this.$balloonContainer, 'flex');

        setTimeout(() => {
            this.$balloonContainer.classList.add(this.balloonShowClass);
        }, delayRender);
    }

    _hideBalloon() {
        const delay = 500;

        this.$balloonContainer.classList.remove(this.balloonShowClass);

        setTimeout(() => {
            Utils.hide(this.$balloonContainer);
        }, delay);
    }

    _checkVisibleBalloon() {
        return !(this.$balloonContainer.style.display === 'none');
    }

    _setCenterMapByWidth() {
        let screen = window.innerWidth < this.tableLandscape ? 'mobile' : 'desktop';

        window.addEventListener('resize', () => {
            if (screen === 'mobile') {
                if (window.innerWidth > this.tableLandscape) {
                    screen = 'desktop';
                    this.map.setCenter(this.dataMap.center);
                }
            } else if (screen === 'desktop') {
                if (window.innerWidth < this.tableLandscape) {
                    screen = 'mobile';
                    this.map.setCenter(this.dataMap.centerSecond);
                }
            }
        });
    }
}

export default GMainMap;

