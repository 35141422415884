import Cookies from 'js-cookie';

class CookiesNotice {
    constructor() {
        this.showDelay = 1500;
        this.cookieName = 'cookie-accepted';
    }

    /**
     * Метод инициализирует модуль
     * @param {HTMLElement} wrapper - обертка компонента.
     */
    init(wrapper) {
        this.wrapper = wrapper;
        this.button = this.wrapper.querySelector('.j-cookies-notice__button');
        this._bindEvents();
        this._check();
    }

    /**
     * Метод навешивает обработчики на элементы
     * @private
     */
    _bindEvents() {
        this.button.addEventListener('click', this._close.bind(this));
    }

    /**
     * Метод проверяет наличие куки
     * @private
     */
    _check() {
        const isCookieSet = Cookies.get(this.cookieName);

        if (!isCookieSet) {
            this._show();
        }
    }

    /**
     * Метод показывает уведомление.
     * @private
     */
    _show() {
        // Добавляем доп класс, что-бы уведомление не прыгало при загрузке страницы
        this.wrapper.classList.add('is-show');

        setTimeout(() => {
            this.wrapper.classList.add('is-open');
        }, this.showDelay);
    }

    /**
     * Метод закрывает уведомление и устанавливает куку.
     * @private
     */
    _close() {
        Cookies.set(this.cookieName, true);

        this.wrapper.classList.remove('is-open');
    }
}

export default CookiesNotice;
