import $ from 'jquery';
import Slick from 'slick-carousel'; // eslint-disable-line
import Mediator from '../../common/scripts/mediator'; // eslint-disable-line

class Carousel {
    constructor() {
        this.mediator = new Mediator();

        this.sliderClass = 'j-carousel-init';
        this.containerClass = 'j-carousel-container';
        this.prevClass = 'j-carousel-prev';
        this.nextClass = 'j-carousel-next';
        this.counterClass = 'j-carousel-counter';
        this.arrowsClass = 'j-carousel-arrows';
        this.zoomClass = 'j-carousel-zoom';
        this.slide = 'j-carousel-slide';
    }

    init() {
        this._initElements();
        this._initSlick();
    }

    _initElements() {
        this.$carousels = $(`.${this.sliderClass}`);
    }

    _initSlick() {
        this.$carousels.each((index, carousel) => {
            if ($(carousel).hasClass('slider-init')) {
                return;
            }

            const current = carousel.dataset.current;
            const firstSlider = 0;
            const sync = carousel.dataset.sync;
            const dataInti = {
                dots        : false,
                arrows      : false,
                initialSlide: Number(current) || firstSlider
            };

            if (sync) {
                dataInti.asNavFor = sync;

                $(sync).slick({
                    dots        : false,
                    arrows      : false,
                    initialSlide: Number(current) || firstSlider,
                    asNavFor    : carousel
                });
            }

            const $slick = $(carousel).slick(dataInti);
            const $container = $slick.closest(`.${this.containerClass}`);

            $(carousel).addClass('slider-init');

            this._initArrow($container, $slick);
            this._initCounter($container, $slick);
            this._checkShowArrow($container, $slick);
            this._initZoom($container, $slick);
        });
    }

    _initArrow($container, $slick) {
        const $arrowPrev = $container.find(`.${this.prevClass}`);
        const $arrowNext = $container.find(`.${this.nextClass}`);

        $arrowPrev.click(() => {
            $slick.slick('slickPrev');
        });

        $arrowNext.click(() => {
            $slick.slick('slickNext');
        });
    }

    _initCounter($container, $slick) {
        const first = 0;
        const one = 1;
        const countSlide = $slick[first].slick.slideCount;
        const currentSlide = $slick[first].slick.currentSlide + one;
        const $counterClass = $container.find(`.${this.counterClass}`);

        $counterClass.html(`<span>${currentSlide}/</span>${countSlide}`);

        $slick.on('init reInit afterChange', (event, slick, newSlide) => {
            $counterClass.html(`<span>${newSlide + one}/</span>${countSlide}`);
        });
    }

    _checkShowArrow($container, $slick) {
        const first = 0;
        const one = 1;

        if ($slick[first].slick.slideCount <= one) {
            $container.find(`.${this.arrowsClass}`).hide();
        }
    }

    _initZoom($container, $slick) {
        const $zoom = $container.find(`.${this.zoomClass}`);
        const first = 0;
        const that = this;

        $zoom.click(function clickZoom(event) {
            event.preventDefault();

            const $slidesSlick = $slick[first].slick.$slides;
            const sendSlides = [];
            const currentSlide = $slick[first].slick.currentSlide;

            $slidesSlick.each((index, slide) => {
                const $slideContent = $(slide).find(`.${that.slide}`);

                if (!$slideContent) {
                    return;
                }

                const $imgSlide = $slideContent.find('img')[first];

                if ($imgSlide) {
                    sendSlides.push($imgSlide.getAttribute('src'));
                }
            });

            that.mediator.publish('zoomCarousel', this, sendSlides, currentSlide); // eslint-disable-line
        });
    }
}

export default Carousel;

