import 'jquery-match-height';
import $ from 'jquery';

class MatchHeight {
    constructor(option) {
        this.elementsClass = option.element;
    }

    init() {
        this._initElements();
        this.initMathHeight();
    }

    _initElements() {
        this.$elements = $(this.elementsClass);
    }

    initMathHeight() {
        if (!this.$elements.length) {
            return;
        }

        this.$elements.matchHeight();
    }
}

export default MatchHeight;

